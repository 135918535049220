import traverse  from 'traverse'

export default function trimObjValues(obj) {
  console.log('this is trimming obj values')

  let result = {}

    let removedHypens = Object.keys(obj).reduce((acc, curr) => {

    // console.log(`${obj[curr]} is ${typeof obj[curr] === 'string' ? "String" : "Number"}`)
    // acc[curr] = typeof obj[curr] === 'string' ? obj[curr].trim() : obj[curr]

    acc[curr] = typeof obj[curr] === 'object' ? obj[curr] : String(obj[curr]).trim().replace('-', '')
    return acc;
  }, {});


   let forTraversal = traverse(removedHypens)

   let paths = forTraversal.paths()
   console.log('the paths are')
   console.log(paths)

   //traverse each path extracted
   paths.forEach((path) => {

    //check if element on that path is object
    let valueOnThatPath = forTraversal.get(path)

    //if path array is greater than 0 and value on that path is not object, add to result 
    if(path.length > 0 && typeof valueOnThatPath !== 'object'){
      let pathString = path.reduce((acc, curr) => {
        let result = acc === '' ? `${curr}` : `${acc}.${curr}`
        return result
      }, '')

      result[`${pathString}`] = valueOnThatPath
    }

   })


  return result
}