export default function formatDateInputs(billerId, billDetails){
    //pagibig biller id
    let pagibigbillerid = "640648176312065b36aea305"

    console.log('biller id given', billerId)

    if(billerId === pagibigbillerid){
        console.log('will change')
        let billDate = billDetails["otherInfo.BillDate"].split("-")
        let dueDate  = billDetails["otherInfo.DueDate"].split("-")
        billDetails["otherInfo.BillDate"] = `${billDate[0]},${billDate[1]}`
        billDetails["otherInfo.DueDate"] = `${dueDate[0]},${dueDate[1]}`
    }

    return billDetails

}