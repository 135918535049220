export default function getTelecomThumbnail(brand = ''){
	switch(brand) {
		case 'ROW':
			return "https://upload.wikimedia.org/wikipedia/commons/0/0b/Blue_globe_icon.svg"
		case 'MERALCO':
		case 'PLDTPH':
		case 'TNTPH':
		case 'SMARTPH': 
		case 'GLOBE':
			return `https://sparkle-vortex.imgix.net/${brand}.png?w=120&h=120`
		case 'CIGNAL':
			return `https://sparkle-vortex.imgix.net/${brand}1.png?w=120&h=120`
	}
}